import React from "react";
import { Container, Card } from "react-bootstrap";
import { FaDog } from "react-icons/fa";

interface DogErrorProps {
  missing?: string;
  message?: string;
}

const DogError: React.FC<DogErrorProps> = ({
  missing = "something important",
  message,
}) => {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center vh-100 text-center">
      <Card style={{ maxWidth: "500px", padding: "20px" }} className="shadow-lg">
        <FaDog size={50} color="brown" />
        <h1 className="mt-3">Uh-oh! 🐾</h1>
        <h3>{message || `${missing} is missing!`}</h3>
        <p className="text-muted">
          Looks like someone 🐶 chewed up your <strong>{missing}</strong>... or maybe it just ran away!
        </p>
        <img
          src="https://media.giphy.com/media/3o6Zt481isNVuQI1l6/giphy.gif"
          alt="Funny Dog"
          className="img-fluid rounded mb-3"
        />
      </Card>
    </Container>
  );
};

export default DogError;