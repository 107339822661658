import React, { useState, useEffect } from "react";
import Button from "../atoms/Button";

// @ts-ignore
interface ExtendedNavigator extends Navigator {
  wakeLock?: {
    request: (type: "screen") => Promise<WakeLockSentinel>;
  };
}

interface WakeLockSentinel {
  release: () => Promise<void>;
  addEventListener: (type: "release", listener: () => void) => void;
}

const FullScreenButton: React.FC = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);

  // Function to toggle fullscreen mode
  const toggleFullScreen = async () => {
    if (!document.fullscreenElement) {
      try {
        await document.documentElement.requestFullscreen();
        setIsFullScreen(true);
      } catch (err) {
        console.error("Error entering fullscreen:", err);
      }
    } else {
      try {
        await document.exitFullscreen();
        setIsFullScreen(false);
      } catch (err) {
        console.error("Error exiting fullscreen:", err);
      }
    }
  };

  // Function to request wake lock (prevents screen saver)
  const requestWakeLock = async () => {
    const navigatorWithWakeLock = navigator as ExtendedNavigator;
    if (navigatorWithWakeLock.wakeLock) {
      try {
        const lock = await navigatorWithWakeLock.wakeLock.request("screen");
        setWakeLock(lock);

        // Re-request wake lock if it gets released
        lock.addEventListener("release", () => {
          console.log("Wake Lock was released");
          setWakeLock(null);
        });
      } catch (err) {
        console.error("Error requesting wake lock:", err);
      }
    } else {
      console.warn("Wake Lock API not supported in this browser.");
    }
  };

  useEffect(() => {
    requestWakeLock();

    return () => {
      if (wakeLock) {
        wakeLock.release();
      }
    };
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      console.log("Refreshing page...");
      window.location.reload();
    }, 360000); // 6 minutes

    return () => clearTimeout(interval);
  }, []);

  return (
    <Button onClick={toggleFullScreen} text={isFullScreen ? "Exit Fullscreen" : "Enter Fullscreen"} />
  );
};

export default FullScreenButton;