import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PatientCard from '../organisms/PatientCard';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { RootState } from '../../redux/rootReducer';
import { checkAuthState } from '../../redux/slices/authSlice';
import { Badge, Patient, Session, Sex, Device } from '../../types';
import { fetchDevicesWithVitals, selectDevices, selectDevicesLoading, selectDevicesError, fetchDevicesWithVitalsData } from '../../redux/slices/devices';
import websocketService from '../../services/websocketService';
import DogError from '../organisms/Error';
import ControlPanel from '../organisms/VitalsControlPanel';

const defaultPatient: Patient = {
  id: "",
  customerId: "--",
  species: '--',
  breed: 'house',
  name: "<session not started>",
  sex: Sex.FEMALE_SPAYED,
  doctor: '--',
  phone: '--',
}
const defaultBadge: Badge = {
  color: "pink",
  number: "--",
  location: "--"
}
const defaultSession: Session = {
  id: "",
  startTime: ""
}

const Vitals: React.FC = () => {
  const { deviceId } = useParams();
  const dispatch = useDispatch<any>()
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const devices = useSelector(selectDevices);
  const loading = useSelector(selectDevicesLoading);
  const error = useSelector(selectDevicesError);


  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchDevicesWithVitals());
    }
  }, [dispatch, isAuthenticated]);



  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    if (!user) {
      return;
    }
    if (user && !user.token) {
      return;
    }
    if (!deviceId) {
      return;
    }
    if (devices.length < 1) {
      return;
    }
    const jwt = user.token;
    if (jwt) {
      websocketService.connect(jwt);
      websocketService.joinRoom(deviceId);
    }

    return () => {
      websocketService.disconnect();
    };
  }, [isAuthenticated, user, devices]);

  const device = devices.find((device) => device.id === deviceId);

  if (!deviceId) {
    return (
      <DogError missing="Device Id" />
    );
  }

  if (!device) {
    return (
      <DogError missing="Device" />
    );
  }

  if (!isAuthenticated || loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  const session = device?.session || { ...defaultSession }
  const aPatient = session?.patient || { ...defaultPatient };
  const aBadge = { ...defaultBadge };
  if (device.colorCode) {
    aBadge.color = device.colorCode;
    aBadge.location = device.colorCode;
  }
  if (device.inClinicLocation) {
    aBadge.location = device.inClinicLocation;
  }
  if (device.name) {
    aBadge.location = device.name;
  }
  if (device.inClinicLocationNumber) {
    aBadge.number = `${device.inClinicLocationNumber}`;
  }



  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-8">
          <PatientCard
            sessionId={session.id}
            patient={aPatient}
            badge={aBadge}
            deviceId={deviceId}
            onOpenOverlay={(machine_id) => {
            }}
          />
        </div>
        <div className='col-md-4'>
          <ControlPanel onAddPatient={()=>{}} onEditDevice={()=>{}} onPauseSession={()=>{}} onRemovePatient={()=>{}} onViewStatistics={()=>{}} hasPatient={false} />
        </div>
      </div>
    </div>
  );
};

export default Vitals;
