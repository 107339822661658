import React from "react";
import { Button, ButtonGroup, Container } from "react-bootstrap";
import { FaUserPlus, FaUserMinus, FaPause, FaChartBar, FaCog } from "react-icons/fa";

interface ControlPanelProps {
  hasPatient: boolean; // Determines button states
  onAddPatient: () => void;
  onRemovePatient: () => void;
  onPauseSession: () => void;
  onViewStatistics: () => void;
  onEditDevice: () => void;
}

const ControlPanel: React.FC<ControlPanelProps> = ({
  hasPatient,
  onAddPatient,
  onRemovePatient,
  onPauseSession,
  onViewStatistics,
  onEditDevice,
}) => {
  return (
    <Container className="text-center mt-4">
      <ButtonGroup vertical>
        <Button 
          variant="success" 
          onClick={onAddPatient} 
          className="mb-2" 
          disabled={hasPatient} // Disabled if patient exists
        >
          <FaUserPlus className="me-2" /> Add Patient
        </Button>
        <Button 
          variant="danger" 
          onClick={onRemovePatient} 
          className="mb-2" 
          disabled={!hasPatient} // Disabled if no patient
        >
          <FaUserMinus className="me-2" /> Remove Patient
        </Button>
        <Button 
          variant="warning" 
          onClick={onPauseSession} 
          className="mb-2" 
          disabled={!hasPatient} // Disabled if no patient
        >
          <FaPause className="me-2" /> Pause Session
        </Button>
        <Button 
          variant="info" 
          onClick={onViewStatistics} 
          className="mb-2" 
          disabled={!hasPatient} // Disabled if no patient
        >
          <FaChartBar className="me-2" /> View Session Statistics
        </Button>
        <Button variant="secondary" onClick={onEditDevice}>
          <FaCog className="me-2" /> Edit Device Properties
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default ControlPanel;