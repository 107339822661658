import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { RootState } from '../rootReducer';
import { Device } from '../../types';
import { fetchWithJwt } from '../../utils/fetchWithJwt';
import { copyWithoutId } from '../../utils/copyWithoutId';
import { updateData } from './dataSlice';

interface DeviceState {
    devices: Device[];
    loading: boolean;
    error: string | null;
}

const initialState: DeviceState = {
    devices: [],
    loading: false,
    error: null,
};

const deviceSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        fetchDevicesStart(state) {
            state.loading = true;
        },
        fetchDevicesSuccess(state, action: PayloadAction<Device[]>) {
            state.loading = false;
            state.devices = action.payload;
        },
        fetchDevicesFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        addDeviceSuccess(state, action: PayloadAction<Device>) {
            state.devices.push(action.payload);
        },
        updateDeviceSuccess(state, action: PayloadAction<Device>) {
            const index = state.devices.findIndex(device => device.id === action.payload.id);
            if (index !== -1) {
                state.devices[index] = action.payload;
            }
        },
        deleteDeviceSuccess(state, action: PayloadAction<string>) {
            state.devices = state.devices.filter(device => device.id !== action.payload);
        },
    },
});

export const {
    fetchDevicesStart,
    fetchDevicesSuccess,
    fetchDevicesFailure,
    addDeviceSuccess,
    updateDeviceSuccess,
    deleteDeviceSuccess,
} = deviceSlice.actions;

export const fetchDevices = (): AppThunk => async dispatch => {
    try {
        dispatch(fetchDevicesStart());
        const response = await fetchWithJwt('/devices');
        const data: Device[] = await response.json();
        dispatch(fetchDevicesSuccess(data));
    } catch (error: any) {
        dispatch(fetchDevicesFailure(error.toString()));
    }
};
const transformVitalsData = (input: {
    machine_id: string;
    temp: number;
    weight: number;
    pulse: number;
    resp: number;
    created_at: string;
}) => {
    return {
        ts: new Date().toISOString(), // Using current timestamp instead of created_at
        hr: input.pulse, // Assuming pulse corresponds to heart rate
        avgHR: -1, // No average HR provided
        actHR: input.pulse, // Active HR matches pulse
        rr: input.resp, // Assuming resp corresponds to respiration rate
        avgRR: -1, // No average RR provided
        actRR: input.resp, // Active RR matches respiration rate
        gt: {
            hr: -1, // No ground truth HR provided
            rr: -1 // No ground truth RR provided
        },
        stale: false, // No information provided, assuming stale as default
        temp: input.temp, // Directly mapping temperature
        session_id: null, // No session ID provided
        machine_id: input.machine_id, // Directly mapping machine_id
        weight: input.weight, // Directly mapping weight
        validTemp: false, // No validation rule for temp, assuming false
        meta: {
            continuousData: true, // Assuming data is continuous
            gt: false, // Assuming ground truth is always true
            validGt: false, // No information provided, assuming false
            clippedScope: false, // No information on clipping scope
            clippedVibration: false, // Assuming no vibration clipping
            significateWeight: true, // Assuming weight is significant
            weightSettled: true, // Assuming weight is settled
            validVibration: true, // Assuming vibration data is valid
            heartbeat: true, // Assuming heartbeat detected
            vhrValid: true, // Assuming valid HR
            vrrValid: true, // Assuming valid RR
            vrr: input.resp, // Mapping respiration rate
            wrrValid: false, // No information provided, assuming false
            wrr: input.resp, // No value for weighted RR
            shrValid: true, // Assuming short HR valid
            frrValid: false // No information provided, assuming false
        }
    };
};

export const fetchDevicesWithVitalsData = (devices:Device[]): AppThunk => async dispatch => {
    try {
        const deviceIds = devices.map((device) => device.id)
        const response = await fetchWithJwt(`/devices/latest-data?deviceIds=${deviceIds.join(",")}`);
        const data = await response.json();
        data.forEach((deviceVitals: any) => {
            dispatch(updateData({ roomId: deviceVitals.machine_id, data: transformVitalsData(deviceVitals) as any }));
        });
    } catch (error: any) {
        dispatch(fetchDevicesFailure(error.toString()));
    }
};

export const fetchDevicesWithVitals = (): AppThunk => async dispatch => {
    try {
        dispatch(fetchDevicesStart());
        const response = await fetchWithJwt('/devices/vitalboard');
        const data: Device[] = await response.json();
        dispatch(fetchDevicesSuccess(data));
    } catch (error: any) {
        dispatch(fetchDevicesFailure(error.toString()));
    }
};

export const addDevice = (device: Device): AppThunk => async dispatch => {
    try {
        const response = await fetchWithJwt('/devices', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(copyWithoutId(device)),
        });
        const data: Device = await response.json();
        dispatch(addDeviceSuccess(data));
    } catch (error: any) {
        dispatch(fetchDevicesFailure(error.toString()));
    }
};

export const updateDevice = (device: Device): AppThunk => async dispatch => {
    try {
        const response = await fetchWithJwt(`/devices/${device.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(device),
        });
        const data: Device = await response.json();
        dispatch(updateDeviceSuccess(data));
    } catch (error: any) {
        dispatch(fetchDevicesFailure(error.toString()));
    }
};

export const deleteDevice = (id: string): AppThunk => async dispatch => {
    try {
        await fetchWithJwt(`/devices/${id}`, {
            method: 'DELETE',
        });
        dispatch(deleteDeviceSuccess(id));
    } catch (error: any) {
        dispatch(fetchDevicesFailure(error.toString()));
    }
};

export const selectDevices = (state: RootState) => state.devices.devices;
export const selectDevicesLoading = (state: RootState) => state.devices.loading;
export const selectDevicesError = (state: RootState) => state.devices.error;

export default deviceSlice.reducer;
